import { useReservedLPStatus } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { StrategyVaultInfo, UserPosition, vaultVersion } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Position } from '@apps-orangefi/ui/atoms'
import { DepositProps, WithdrawProps } from '@apps-orangefi/ui/molecules/strategy/forms'
import {
  DepositContainer,
  WithdrawContainer,
  RedeemReservedLiquidityContainer,
  type DepositContainerProps,
  type WithdrawContainerProps,
  type RedeemReservedLiquidityContainerProps,
} from '@apps-orangefi/ui/organisms/lpdfi'
import { Tab } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

export type WalletProps = {
  onConnect: () => void
  onSwitchChainModal: () => void
  onShowInvalidAddressModal: (e?: Event) => void
  isConnected: boolean
  isActiveChainSupported: boolean
}

export type ContractWidgetContainerProps = {
  deposit: Omit<DepositContainerProps, 'protcolFee'>
  withdraw: Omit<WithdrawContainerProps, 'isClosed'>
  redeemReserved?: Omit<RedeemReservedLiquidityContainerProps, 'reservedLPStatus'> & {
    poolAddress: AddressType | undefined
  }
  wallet: WalletProps
  isFetching: boolean
  userPosition: UserPosition | undefined
  isClosed: boolean
  isDeprecatedVault?: boolean
  tokenSymbol: string | undefined
  baseTokenPriceUSD: BN
}

type Props = {
  contractWidgetProps: ContractWidgetContainerProps
  productInfo: StrategyVaultInfo
}

export const ContractWidgetContainer = ({
  contractWidgetProps: {
    deposit,
    withdraw,
    redeemReserved,
    wallet,
    isFetching,
    userPosition,
    isClosed,
    isDeprecatedVault,
    tokenSymbol,
    baseTokenPriceUSD,
  },
  productInfo: { info },
}: Props) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()

  const yourPosition = numberWithShortScale(userPosition?.myPosition.position ?? new BN(0), 4)
  const yourPositionUSD = numberWithShortScale(
    userPosition?.myPosition.position.multipliedBy(baseTokenPriceUSD) ?? new BN(0)
  )

  const reservedLPStatus = useReservedLPStatus(
    account,
    redeemReserved?.poolAddress,
    redeemReserved?.handlerAddress,
    redeemReserved?.reserveProxyAddress,
    redeemReserved?.chainId
  )

  const tabs = useMemo(() => {
    if (info.version === vaultVersion.LPDfi) {
      return [t('WIDGET.TABS.DEPOSIT'), t('WIDGET.TABS.RESERVE'), t('WIDGET.TABS.WITHDRAW')]
    } else {
      return [t('WIDGET.TABS.DEPOSIT'), t('WIDGET.TABS.WITHDRAW')]
    }
  }, [t, info])

  return (
    <div className="flex flex-col gap-0.5 w-full h-fit">
      <div className="flex flex-row justify-between items-center rounded-t-2xl rounded-b dark:bg-gray-850 py-4 px-5">
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between w-full items-center">
            <div className="type-lg-semibold dark:text-white">{t('MODEL.USER.YOUR_POSITION')}</div>
            <div className="flex flex-row justify-between items-center">
              <div className="type-lg-semibold dark:text-white font-mono">${yourPositionUSD}</div>
              <div className="type-sm-medium dark:text-gray-400 font-mono ml-3">
                {yourPosition}
                {tokenSymbol && <span className="ml-1.5">{tokenSymbol}</span>}
              </div>
            </div>
          </div>
          {!!reservedLPStatus.totalAmountUSD && !reservedLPStatus.totalAmountUSD.isZero() && (
            <div className="dark:text-gray-300 self-end text-sm flex-row flex items-center">
              (reserved
              <span className="ml-2 font-mono">
                ${numberWithShortScale(reservedLPStatus.totalAmountUSD, 2)}
              </span>
              )
            </div>
          )}
        </div>
      </div>
      <Tab.Group defaultIndex={isClosed ? 1 : 0}>
        <Tab.List className="flex flex-row gap-0.5 w-full justify-evenly">
          {tabs.map((tab, i) => (
            <Tab
              disabled={(isClosed || isDeprecatedVault) && i === 0}
              className={({ selected }) => {
                const bgClass = selected
                  ? 'dark:bg-gray-800 dark:text-white'
                  : 'dark:bg-gray-850 dark:text-gray-300'
                return `${bgClass} rounded type-base-semibold w-full py-4 focus:outline-none`
              }}
              key={i}
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full dark:bg-gray-850 rounded-t rounded-b-2xl">
          <Tab.Panel>
            {/* TODO: this component handle V1, LPDfiContainer */}
            <DepositContainer deposit={deposit} wallet={wallet} />
          </Tab.Panel>
          <Tab.Panel>
            <WithdrawContainer
              withdraw={{
                ...withdraw,
                isClosed,
              }}
              wallet={wallet}
            />
          </Tab.Panel>
          {info.version === vaultVersion.LPDfi && !!redeemReserved && (
            <Tab.Panel>
              <RedeemReservedLiquidityContainer
                redeemReservedLP={{
                  ...redeemReserved,
                  reservedLPStatus,
                }}
                wallet={wallet}
                className="mt-3"
              />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
